@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html,
body {
  font-family: 'Montserrat', sans-serif;
}

.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}
