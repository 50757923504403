/* carouselStyles.css */
.nav_btn {
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.nav_btn_right {
  right: 10px;
}

.nav_btn_left {
  left: 10px;
}

.nav_btn svg {
  width: 30px;
  height: 30px;
  fill: #000; /* Changer la couleur des icônes */
}
